img {
    object-fit: contain;
    overflow: hidden;
    max-width: 100%;
    min-width: 100%;
}
  
img:last-child {
    padding-bottom: 0;
}

.image-text {
    position: absolute;
    right: 1em;
    bottom: 1em;
    z-index: 100;
}

.image-wrapper {
    position: relative;
    margin-bottom: 20px;
}

.image-text {
    color: #6c6c6c;
    font-size: 0.8em;
    text-transform: none;
}


.image-text> a {
    color: #6c6c6c;
    text-transform: none;
}

.image-text> a:visited {
    color: #6c6c6c;
}

.image-text> a:active {
    color: #6c6c6c;
}

.image-text> a:hover {
    color: #6c6c6c;
    text-decoration: underline;
}