a {
  color: white;
  text-decoration: none;
  text-transform: capitalize;
  mix-blend-mode: difference;
  -webkit-mix-blend-mode: difference;
  -moz-mix-blend-mode: difference;
  -o-mix-blend-mode: difference;
  -ms-mix-blend-mode: difference;
}

a.active {
  text-decoration: underline;
  color: white;
  mix-blend-mode: difference;
  -webkit-mix-blend-mode: difference;
  -moz-mix-blend-mode: difference;
  -o-mix-blend-mode: difference;
  -ms-mix-blend-mode: difference;
}

a:visited {
  color: white;
  mix-blend-mode: difference;
  -webkit-mix-blend-mode: difference;
  -moz-mix-blend-mode: difference;
  -o-mix-blend-mode: difference;
  -ms-mix-blend-mode: difference;
}

header {
  background: transparent;
  color: white;
  font-size: 1.4em;
  height: 75px;
  padding: 20px 20px;
  position: sticky;
  text-decoration: none;
  top: 0;
  width: max-content;
  mix-blend-mode: difference;
  -webkit-mix-blend-mode: difference;
  -moz-mix-blend-mode: difference;
  -o-mix-blend-mode: difference;
  -ms-mix-blend-mode: difference;
  transform: translate3d(0,0,0);
  z-index: 1000;
}

.links {
  display: flex;
  justify-content: space-between;
    mix-blend-mode: difference;
  -webkit-mix-blend-mode: difference;
  -moz-mix-blend-mode: difference;
  -o-mix-blend-mode: difference;
  -ms-mix-blend-mode: difference;
}
