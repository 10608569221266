@font-face {
    font-family: 'grotesk';
    src: url('./fonts/NeatGrotesk-Regular.otf');
}

.App {
  font-family: 'grotesk', serif;
  margin: 0 auto;
  background-color: white;
}
