section {
    padding: 0 20px;
}

section:not(:first-child) {
    margin-top: 1em;
}

ul {
    padding: 0;
    list-style: none;
    margin: 0;
}

p {
    margin: 0;
    max-width: 68ch;
}